import React from "react";
import {
    Chart as ChartJS,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
} from "chart.js";
import { Scatter } from "react-chartjs-2";
ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

const getDatapoints = (values, index) => {
    let datapoints = [];
    for (let i = 0; i < values.length; i++) {
        datapoints.push(parseFloat(values[i][index]));
    }
    datapoints = datapoints.filter((v) => !isNaN(v) && v !== undefined);
    return datapoints;
};

export default function ScatterChart(props) {
    const { labelX, labelY, labelData, xIndex, yIndex, showTableEntry } = props;

    let xData = getDatapoints(labelData, xIndex);
    let yData = getDatapoints(labelData, yIndex);

    if (
        xData.length == 0 ||
        yData.length === 0 ||
        xData.length !== yData.length
    ) {
        console.warn(`Mismatch in Size x:${xData.length} vs y:${yData.length}`);
        return <div></div>;
    }

    let scatterData = [];
    for (let i = 0; i < xData.length; i++) {
        scatterData.push({
            x: xData[i],
            y: yData[i],
            meta : {
                // The ID field of the Dataset
                dsetIdx: labelData[i][0]
            },
        });
    }

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            x: {
                beginAtZero: true,
                grid: {
                    color: "rgba(255,255,255,0.4)",
                    borderColor: "white",
                },
                ticks: {
                    color: "white",
                },
                title: {
                    display: true,
                    text: labelX,
                    color: "white",
                },
            },
            y: {
                beginAtZero: true,
                grid: {
                    color: "rgba(255,255,255,0.4)",
                    borderColor: "white",
                },
                ticks: {
                    color: "white",
                },
                title: {
                    display: true,
                    text: labelY,
                    color: "white",
                },
            },
        },
        onClick: (event, values) => {
            console.log("Click");
            console.log(event);
            console.log(values);
            if(values.length === 0){
                return;
            }
            const obj = values[0]["element"]["$context"]["raw"]
            if (obj === undefined){
                return;
            }

            console.log(obj);
            const tableIdx = obj["meta"]["dsetIdx"];
            showTableEntry(tableIdx);
        },
    };

    // const plugin = {
    //     id: 'custom_canvas_background_color',
    //     beforeDraw: (chart) => {
    //       const ctx = chart.canvas.getContext('2d');
    //       ctx.save();
    //       ctx.globalCompositeOperation = 'destination-over';
    //       ctx.fillStyle = 'white';
    //       ctx.fillRect(0, 0, chart.width, chart.height);
    //       ctx.restore();
    //     }
    //   };

    const data = {
        datasets: [
            {
                label: `${labelX} vs ${labelY}`,
                data: scatterData,
                backgroundColor: "rgba(255, 99, 132, 1)",
                radius: 15,
                hoverRadius: 10,
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            for (const v of labelData) {
                                if (
                                    v[xIndex] == context.parsed.x &&
                                    v[yIndex] == context.parsed.y
                                ) {
                                    return `ID(${v[0]}) :  (${context.parsed.x}, ${context.parsed.y})`;
                                }
                            }
                            return "Not found";
                        },
                    },
                },
            },
        ],
    };

    return <Scatter options={options} data={data} />;
}
