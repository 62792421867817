import { Checkbox, FormGroup, FormControlLabel, Grid } from "@mui/material";
import React from "react";

export default function AtomTypeSelector(props) {
    const { atomsFilter, setAtomsFilter } = props;

    return (
        <FormGroup style={{width: "200px", height: "150px", marginTop: "2em"}}>
            {Object.keys(atomsFilter).map((atom) => (
                <FormControlLabel
                    key={atom}
                    control={
                        <Checkbox
                            checked={atomsFilter[atom]}
                            onChange={() =>{
                                let tmp = {...atomsFilter};
                                tmp[atom] = !atomsFilter[atom];
                                setAtomsFilter(tmp);
                            }
                            }
                            sx={{
                                ".MuiCheckbox-root": {
                                    fill: "white",
                                    borderColor: "white",
                                },
                                ".MuiSvgIcon-root": {
                                    fill: "var(--text-color)",
                                },
                            }}
                        />
                    }
                    label={atom}
                />
            ))}
        </FormGroup>
    );
}
