import React, { useState, useEffect } from "react";
import socketIOClient from "socket.io-client";
import JSZip from "jszip";
import FileSaver from "file-saver";

import "./App.css";
import LogoImg from "./img/logo.jpg";
import EUImg from "./img/ECH2020.png";

import NumberInputField from "./components/Input";
import InputTabs from "./components/InputTabs";
import OutputTabs from "./components/OutputTabs";
import {
    CircularProgress,
    TextareaAutosize,
    Button,
    Grid,
} from "@mui/material";
import AtomTypeSelector from "./components/AtomTypeSelector";
import { Link } from "react-router-dom";

const ENDPOINT = process.env.REACT_APP_WS_ENDPOINT;
console.log("WS ENDPOINT: " + ENDPOINT);

function App() {
    const [calculationDone, setCalculationDone] = useState(true);
    const [solubilityThreshold, setSolubilityThreshold] = useState(0.0);
    const [halfcellThreshold, setHalfcellThreshold] = useState(0.0);
    const [molSizeFilter, setMolSizeFilter] = useState(50);
    /*
     */
    const [reactionTemplates, setReactionTemplates] = useState(
        [
            "[CX4,CX3:1]-[C:2]([CX4,CX3:3])=[O:4].[H:5]-[H:6]>>[CX4,CX3:1]-[C:2]([CX4,CX3:3])([H:5])-[O:4]-[H:6]",
            "[CX4:1]-[C:2]([H])=[O:3].[H:4]-[H:5]>>[CX4:1]-[C:2]([H])([H:4])-[O:3]-[H:5]",
            "[OX1:1]=[#6X3:2](-,:[C,N,n,c:3])-,:[#6X3:4](-,:[C,N,n,c:5])=[O:6].[H:7]-[H:8]>>[H:7]-[O:1]-[C:2]([C,N,n,c:3])=[C:4]([C,N,n,c:5])-[O:6]-[H:8]",
            "[#7X2:1]=,:[#6X3:2]-,:[#6X3:3]=,:[#7X2:4].[H:5]-[H:6]>>[H:5]-[#7X3:1]-[#6X3:2]=[#6X3:3]-[#7X3:4]-[H:6]",
            "[O:1]=[C:2]([C,N,c,n:9])-[C,c:3]=,:[C,c:4]-[C:5]([C,N,c,n:10])=[O:6].[H:7]-[H:8]>>[H:7]-[O:1]-[C:2]([C,N,c,n:9])=,:[C,c:3]-,:[C,c:4]=,:[C:5]([C,N,c,n:10])-[O:6]-[H:8]",
            "[C:1](=O)(O)-[C:2]=[C:3]-[C:4](=O)O.[H:5]-[H:6]>>[C:1](=O)(O)-[C:2]([H:5])-[C:3]([H:6])-[C:4](=O)O",
        ].join("\n")
    );
    const [atomTypeFilter, setAtomTypeFilter] = useState({
        C: true,
        H: true,
        O: true,
        N: true,
        F: true,
        S: true,
    });
    const [dataOutputTabs, setDataOutputTabs] = useState([]);
    const [webSocket, setWebSocket] = useState(null);

    const [matchesAndJSONURI, setMatchesAndJSONURI] = useState("");

    useEffect(() => {
        console.log("WS ENDPOINT: " + ENDPOINT);
        const socket = socketIOClient(ENDPOINT);
        // A Temporary storage for the TabsStates
        // because setDataOutputTabs is not updating fast enough
        // and tabs could therefore be lost
        let statesStorage = [];
        // Number of decimals for numbers to round to
        const decimals = 4;

        socket.on("calcResp", (data) => {
            console.log("Calc response: " + data);
            // When the response returns true, start the calculation, otherwise don't
            setCalculationDone(!data);
            setMatchesAndJSONURI("");
            statesStorage = [];
        });

        socket.on("calc_afterFilter", (data) => {
            console.log("calc_afterFilter: ");
            data = JSON.parse(data);

            const newTab = {
                name: "1. Formal filter",
                headers: ["Smiles"],
                values: Object.values(data["SMILES"]).map((smi) => [smi]),
            };

            console.log(`Filter ${statesStorage}`);
            statesStorage.push(newTab);
            statesStorage.sort(
                (a, b) =>
                    Number.parseInt(a.name[0]) - Number.parseInt(b.name[0])
            );
            setDataOutputTabs([...statesStorage]);
        });

        socket.on("calc_afterRedoxPairs", (data) => {
            console.log("calc_afterRedoxPairs: ");
            data = JSON.parse(data);

            const smilesOx = Object.values(data["SMILES"]);
            const smilesRed = Object.values(data["C_Smiles"]);
            const newTab = {
                name: "2. Redox couples",
                headers: ["Smiles Ox", "Smiles Red"],
                values: smilesOx.map((smi, idx) => [smi, smilesRed[idx]]),
            };
            statesStorage.push(newTab);
            statesStorage.sort(
                (a, b) =>
                    Number.parseInt(a.name[0]) - Number.parseInt(b.name[0])
            );
            setDataOutputTabs([...statesStorage]);
        });

        socket.on("calc_afterSAScores", (data) => {
            console.log("calc_afterSAScores: ");
            data = JSON.parse(data);

            const smilesOx = Object.values(data["SMILES"]);
            const smilesRed = Object.values(data["C_Smiles"]);
            const saScoreOx = Object.values(data["A_SAScores"]);
            const saScoreRed = Object.values(data["C_SAScores"]);

            const newTab = {
                name: "3. Synthetic accessibility",
                headers: [
                    "Smiles Ox",
                    "SAScore Ox",
                    "Smiles Red",
                    "SAScore Red",
                ],
                values: smilesOx.map((smi, i) => [
                    smi,
                    saScoreOx[i].toFixed(decimals),
                    smilesRed[i],
                    saScoreRed[i].toFixed(decimals),
                ]),
                papers: [
                    '10:17 Ertl, Peter, and Ansgar Schuffenhauer. "Estimation of synthetic accessibility score of drug-like molecules based on molecular complexity and fragment contributions." Journal of cheminformatics 1.1 (2009): 1-11.',
                ],
            };
            statesStorage.push(newTab);
            statesStorage.sort(
                (a, b) =>
                    Number.parseInt(a.name[0]) - Number.parseInt(b.name[0])
            );
            setDataOutputTabs([...statesStorage]);
        });

        socket.on("calc_afterSolubility", (data) => {
            console.log("calc_afterSolubility: ");
            data = JSON.parse(data);

            const smilesOx = Object.values(data["SMILES"]);
            const smilesRed = Object.values(data["C_Smiles"]);
            const saScoreOx = Object.values(data["A_SAScores"]);
            const saScoreRed = Object.values(data["C_SAScores"]);
            const solubilityOx = Object.values(data["A_Solubility"]);
            const solubilityRed = Object.values(data["C_Solubility"]);

            const newTab = {
                name: "4. Solubility prediction",
                headers: [
                    "Smiles Ox",
                    "SAScore Ox",
                    "Solubility Ox",
                    "Smiles Red",
                    "SAScore Red",
                    "Solubility Red",
                ],
                values: smilesOx.map((smi, i) => [
                    smi,
                    saScoreOx[i].toFixed(decimals),
                    solubilityOx[i].toFixed(decimals),
                    smilesRed[i],
                    saScoreRed[i].toFixed(decimals),
                    solubilityRed[i].toFixed(decimals),
                ]),
                papers: [
                    'Sorkun, Murat Cihan, JM Vianney A. Koelman, and Süleyman Er. "Pushing the limits of solubility prediction via quality-oriented data selection." Iscience 24.1 (2021): 101961.',
                ],
            };
            statesStorage.push(newTab);
            statesStorage.sort(
                (a, b) =>
                    Number.parseInt(a.name[0]) - Number.parseInt(b.name[0])
            );
            setDataOutputTabs([...statesStorage]);
        });

        socket.on("calc_afterPotentials", (data) => {
            console.log("calc_afterPotential: ");
            data = JSON.parse(data);

            const smilesOx = Object.values(data["SMILES"]);
            const smilesRed = Object.values(data["C_Smiles"]);
            const saScoreOx = Object.values(data["A_SAScores"]);
            const saScoreRed = Object.values(data["C_SAScores"]);
            const solubilityOx = Object.values(data["A_Solubility"]);
            const solubilityRed = Object.values(data["C_Solubility"]);

            const halfcellpreds = Object.values(
                data["StandardHalfcellPotentialPred"]
            );
            const newTab = {
                name: "5. Std potential prediction",
                headers: [
                    "Smiles Ox",
                    "SAScore Ox",
                    "Solubility Ox",
                    "Smiles Red",
                    "SAScore Red",
                    "Solubility Red",
                    "Half-Cell Potential Prediction",
                ],
                values: smilesOx.map((smi, i) => [
                    smi,
                    saScoreOx[i].toFixed(decimals),
                    solubilityOx[i].toFixed(decimals),
                    smilesRed[i],
                    saScoreRed[i].toFixed(decimals),
                    solubilityRed[i].toFixed(decimals),
                    halfcellpreds[i].toFixed(decimals),
                ]),
                papers: [
                    'Barker, James, et al. "Rapid Prescreening of Organic Compounds for Redox Flow Batteries: A Graph Convolutional Network for Predicting Reaction Enthalpies from SMILES." Batteries & Supercaps 4.9 (2021): 1482-1490.',
                ],
            };
            statesStorage.push(newTab);
            statesStorage.sort(
                (a, b) =>
                    Number.parseInt(a.name[0]) - Number.parseInt(b.name[0])
            );
            setDataOutputTabs([...statesStorage]);
        });

        socket.on("calc_afterHalfCellMatching", (data) => {
            console.log("calc_afterHalfCellMatching: ");
            data = JSON.parse(data);

            const smilesOx = Object.values(data["SMILES"]);
            const smilesRed = Object.values(data["C_Smiles"]);
            const saScoreOx = Object.values(data["A_SAScores"]);
            const saScoreRed = Object.values(data["C_SAScores"]);
            const solubilityOx = Object.values(data["A_Solubility"]);
            const solubilityRed = Object.values(data["C_Solubility"]);

            const halfcellpreds = Object.values(
                data["StandardHalfcellPotentialPred"]
            );

            const newTab = {
                name: "6. Ranking by std potential",
                headers: [
                    "Smiles Ox",
                    "SAScore Ox",
                    "Solubility Ox",
                    "Smiles Red",
                    "SAScore Red",
                    "Solubility Red",
                    "Half-Cell Potential Prediction",
                ],
                values: smilesOx.map((smi, i) => [
                    smi,
                    saScoreOx[i].toFixed(decimals),
                    solubilityOx[i].toFixed(decimals),
                    smilesRed[i],
                    saScoreRed[i].toFixed(decimals),
                    solubilityRed[i].toFixed(decimals),
                    halfcellpreds[i].toFixed(decimals),
                ]),
            };
            statesStorage.push(newTab);
            statesStorage.sort(
                (a, b) =>
                    Number.parseInt(a.name[0]) - Number.parseInt(b.name[0])
            );
            setDataOutputTabs([...statesStorage]);
        });

        socket.on("calc_afterBestMatches", (data) => {
            console.log("calc_afterBestMatches: ");
            data = Object.values(data);

            let values = data.map(
                (
                    { posolyt, negolyt, "potentialDelta[V]": potentialDelta },
                    i
                ) => [
                    posolyt["SMILES"],
                    posolyt["C_Smiles"],
                    negolyt["SMILES"],
                    negolyt["C_Smiles"],
                    potentialDelta.toFixed(decimals),

                    posolyt["A_SAScores"].toFixed(decimals),
                    posolyt["A_Solubility"].toFixed(decimals),
                    posolyt["C_SAScores"].toFixed(decimals),
                    posolyt["C_Solubility"].toFixed(decimals),
                    posolyt["StandardHalfcellPotentialPred"].toFixed(decimals),

                    negolyt["A_SAScores"].toFixed(decimals),
                    negolyt["A_Solubility"].toFixed(decimals),
                    negolyt["C_SAScores"].toFixed(decimals),
                    negolyt["C_Solubility"].toFixed(decimals),
                    negolyt["StandardHalfcellPotentialPred"].toFixed(decimals),
                ]
            );

            // Sort by the potentialDelta, which is index 2, in descending order
            values.sort((x, y) => y[4] - x[4]);

            const newTab = {
                name: "7. Recombined full cells",
                preHeader: ["Posolyt", "Negolyt", "Potential"],
                headers: [
                    "Posolyt Smiles Ox",
                    "Posolyt Smiles Red",
                    "Negolyt Smiles Ox",
                    "Negolyt Smiles Red",

                    "OCV",

                    "Posolyt SAScore Ox",
                    "Posolyt Solubility Ox",
                    "Posolyt SAScore Red",
                    "Posolyt Solubility Red",
                    "Posolyt Std. Reduction Potential",

                    "Negolyt SAScore Ox",
                    "Negolyt Solubility Ox",
                    "Negolyt SAScore Red",
                    "Negolyt Solubility Red",
                    "Negolyt Std. Reduction Potential",
                ],
                values: values,
            };
            statesStorage.push(newTab);
            statesStorage.sort(
                (a, b) =>
                    Number.parseInt(a.name[0]) - Number.parseInt(b.name[0])
            );
            setDataOutputTabs([...statesStorage]);
        });

        socket.on("calc_afterBestMatchesWith0DParams", (data) => {
            console.log(
                "calc_afterBestMatchesWith0DParams: " //+ JSON.stringify(data)
            );
            const listOfMatchesAndJSON = data["data"];
            console.log(listOfMatchesAndJSON);
            const dataJSON = JSON.stringify(listOfMatchesAndJSON);
            // setMatchesAndJSONURI(`data:application/json,${dataJSON}`);
            setMatchesAndJSONURI(dataJSON);
        });

        socket.on("calc_doneCalculation", (data) => {
            console.log("calc_doneCalculation: " + data);
            statesStorage.sort(
                (a, b) =>
                    Number.parseInt(a.name[0]) - Number.parseInt(b.name[0])
            );
            setDataOutputTabs([...statesStorage]);
            setCalculationDone(true);
        });

        setWebSocket(socket);

        return () => {
            console.log("Disconnecting socket!");
            socket.disconnect();
        };
    }, []);

    const startCalculation = (smiles) => {
        console.log("Sol THreshold");
        console.log(solubilityThreshold);

        if (!webSocket || !calculationDone) {
            return;
        }
        if (!smiles) {
            return;
        }

        if (smiles.length === 0) {
            return;
        }

        let atomWhiteList = [];
        for (let key of Object.keys(atomTypeFilter)) {
            if (atomTypeFilter[key]) {
                atomWhiteList.push(key);
            }
        }

        // Reaction Templates
        const templatesList = reactionTemplates
            .split("\n")
            .map((e) =>
                e.trim().replace("\n", "").replace("\r", "").replace(" ", "")
            )
            .filter((e) => e !== "" && e !== undefined);
        console.log(templatesList);
        webSocket.emit("calc", {
            smiles: smiles,
            generalParams: {
                sizeSmallerThan: Math.floor(molSizeFilter),
                halfCellMatchThreshold: parseFloat(halfcellThreshold),
                solubilityThreshold: parseFloat(solubilityThreshold),
                atomWhiteList: atomWhiteList,
            },
            reactionParams: {
                reactionTemplate: templatesList,
            },
        });
    };

    return (
        <React.Fragment>
            <Grid container justifyContent="center" direction="column">
                <img src={LogoImg} className="logo-img" />
                <h4 className="warning-banner">
                    Prototype - Not ready for production use
                </h4>
            </Grid>

            <Grid
                container
                direction="row"
                justifyContent="center"
                rowSpacing={1}
            >
                <NumberInputField
                    decimals={true}
                    text="Min. Solubility [mol / l]"
                    placeholder={"In mol / l"}
                    setFunc={setSolubilityThreshold}
                    value={solubilityThreshold}
                />
                <NumberInputField
                    decimals={true}
                    text="Min. OCV [V]"
                    placeholder={"In Volt"}
                    setFunc={setHalfcellThreshold}
                    value={halfcellThreshold}
                />

                <NumberInputField
                    text="Max. number of atoms"
                    placeholder={
                        "e.g. 100 means all molecules smaller than 100 atoms."
                    }
                    setFunc={setMolSizeFilter}
                    value={molSizeFilter}
                />
                <Grid item>
                    <AtomTypeSelector
                        atomsFilter={atomTypeFilter}
                        setAtomsFilter={setAtomTypeFilter}
                    />
                </Grid>

                <Grid item style={{ margin: "1em" }}>
                    <h5>Reaction Templates</h5>
                    <TextareaAutosize
                        minRows={1}
                        maxRows={10}
                        aria-label="Reaction Template input field"
                        defaultValue={reactionTemplates}
                        onChange={(e) => {
                            console.log(reactionTemplates);
                            setReactionTemplates(e.target.value);
                        }}
                        style={{ width: "40vw", height: "25vh" }}
                    />
                </Grid>
            </Grid>

            <Grid
                container
                direction="row"
                justifyContent="center"
                rowSpacing={1}
            >
                <InputTabs
                    startCalculationFunc={startCalculation}
                    calculationDone={calculationDone}
                />
            </Grid>

            {calculationDone && matchesAndJSONURI != "" ? (
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    width="100%"
                    rowSpacing={1}
                >
                    <Button
                        onClick={() => {
                            const zip = new JSZip();
                            zip.file(
                                "All0DCellMatches.json",
                                matchesAndJSONURI
                            );

                            const allData = JSON.parse(matchesAndJSONURI);
                            let i = 0;
                            for (const e of allData) {
                                const modelParams = e["zeroDParams"];
                                zip.file(
                                    `Match-${i}.json`,
                                    JSON.stringify(modelParams)
                                );

                                i++;
                            }
                            console.log("Generating zip..");
                            zip.generateAsync({ type: "blob" }).then(function (
                                content
                            ) {
                                console.log("Downloading zip..");
                                FileSaver.saveAs(content, "Matches.zip");
                            });
                        }}
                        variant="contained"
                        color="primary"
                        style={{ width: "10%", margin: "1rem" }}
                    >
                        <p> Download JSON</p>
                    </Button>
                </Grid>
            ) : (
                <React.Fragment />
            )}

            <Grid
                container
                direction="column"
                justifyContent="center"
                width="100%"
                rowSpacing={1}
            >
                {!calculationDone && dataOutputTabs.length == 0 ? (
                    <div style={{ margin: "1em" }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <OutputTabs tabsWithValues={dataOutputTabs} />
                )}
            </Grid>

            <Grid container justifyContent="center" direction="row">
                <Grid item>
                    <p style={{ fontSize: 20 }}>
                        This work is part of{" "}
                        <a
                            style={{ color: "white" }}
                            target="_blank"
                            href="https://www.sonar-redox.eu/en/H2020-project-SONAR.html"
                        >
                            H2020-Project SONAR
                        </a>
                        .
                    </p>
                </Grid>
            </Grid>
            <Grid container direction="column" justifyContent="center">
                <h4>Funding disclaimer:</h4>
                <Grid item>
                    <img src={EUImg} style={{ width: 500 }} />
                </Grid>
                <p style={{ fontSize: 20 }}>
                    This project has received funding from the European Union’s
                    Horizon 2020 research and innovation programme under Grant
                    Agreement no. 875489.
                    <br />
                    This website reflects only the author’s view. The funding
                    agency is not responsible for any use made of the
                    information it contains.
                </p>
                <Link
                    style={{
                        margin: "1em",
                        color: "white",
                        textDecorationLine: "underline",
                    }}
                    to="/imprint"
                >
                    Imprint
                </Link>
            </Grid>
        </React.Fragment>
    );
}

export default App;
