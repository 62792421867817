import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
    Button,
    Tooltip,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import ScatterChart from "./ScatterChart";

import MoleculeStructure from "./MoleculeStructure";
import "./DataTable.css";

const SELECT_STYLE = {
    "&:before": {
        borderColor: "white",
    },
    "&:after": {
        borderColor: "white",
    },
    "&:not(.Mui-disabled):hover::before": {
        borderColor: "white",
    },
    color: "var(--text-color)",
};

const SELECT_SX = {
    ".MuiSelect-icon": {
        fill: "white",
        borderColor: "white",
    },
    ".MuiSelect-select": {
        borderColor: "white",
        color: "white",
    },
    ".MuiSelect-outlined": {
        borderColor: "white",
    },
    ".MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
    },
};

export default function DataTable(props) {
    let { rows, rowNames, id } = props;

    const tableID = `output-table-${id}`;

    rowNames = ["ID", ...rowNames];
    rows = rows.map((r, i) => [i, ...r]);
    const [showAllRowsGraph, setShowAllRowsGraph] = React.useState(true);

    // The default selected element for the x-selector
    const [graphX, setGraphX] = React.useState(0);
    // The default selected element for the y-selector
    const [graphY, setGraphY] = React.useState(0);

    const handleGraphXChange = (event) => {
        setGraphX(event.target.value);
    };

    const handleGraphYChange = (event) => {
        setGraphY(event.target.value);
    };

    // 10 Rows per Page
    const PAGING_SIZE = 5;
    const NUMBER_OF_PAGES = Math.ceil(rows.length / PAGING_SIZE);
    const [showRows, setShowRows] = React.useState(
        rows.slice(0, Math.min(PAGING_SIZE, rows.length))
    );
    const [page, setPage] = React.useState(0);

    React.useEffect(() => {
        // page = 0 -> 1
        let end = (page + 1) * PAGING_SIZE;
        if (page === NUMBER_OF_PAGES - 1) {
            end = rows.length;
        }
        setShowRows(rows.slice(page * PAGING_SIZE, end));
    }, [page]);

    // Shows the table entry with the given index 
    const showTableEntry = (idx) => {
        const newPage = Math.floor(idx / PAGING_SIZE);
        setPage(newPage);
        document.getElementById(tableID).scrollIntoView({behavior: "smooth"});
        
        setTimeout(() => {
            const el = document.getElementById(`output-table-row-${idx}`);
            if(el === null){
                return;
            }
            const oldColor = el.style.backgroundColor; 
            el.style.backgroundColor = "rgba(253,255,71,0.5)";//"#FDFF47";
            setTimeout(() => { el.style.backgroundColor =  oldColor}, 1000);    
        }, 1500);
        
    }


    const drawHead = () => {
        return (
            <TableHead>
                <TableRow>
                    {rowNames.map((name, idx) => (
                        <TableCell
                            key={name + idx}
                            align={"left"}
                            style={{
                                backgroundColor: "var(--sec-bg-color)",
                                color: "white",
                                fontFamily: "arial",
                                fontStyle: "arial",
                            }}
                        >
                            {name}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    };

    const drawTableSmiles = (val) => {
        return (
            <Tooltip
                title={
                    <React.Fragment>
                        <MoleculeStructure
                            id={val}
                            structure={val}
                            height={200}
                            width={200}
                            svgMode
                        />
                    </React.Fragment>
                }
            >
                <p className="tablecelltext">{val}</p>
            </Tooltip>
        );
    };

    return (
        <div style={{ margin: "2.5em" }}>
            <Grid container justifyContent="space-evenly">
                <FormControl
                    // fullWidth
                    style={{
                        background: "var(--main-bg-color)",
                        margin: "1em",
                        padding: "1em",
                    }}
                >
                    <InputLabel
                        id="xlabel"
                        style={{ color: "var(--text-color)" }}
                    >
                        <p>X-Label</p>
                    </InputLabel>
                    <Select
                        labelId="xlabel"
                        value={graphX}
                        // label="X-Label"
                        onChange={handleGraphXChange}
                        style={SELECT_STYLE}
                        sx={SELECT_SX}
                    >
                        {rowNames.map((h, i) => (
                            <MenuItem key={i.toString()} value={i}>{h}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl
                    // fullWidth
                    style={{
                        background: "var(--main-bg-color)",
                        margin: "1em",
                        padding: "1em",
                    }}
                >
                    <InputLabel
                        id="label2"
                        style={{ color: "var(--text-color)" }}
                    >
                        <p>Y-Label</p>
                    </InputLabel>
                    <Select
                        labelId="label2"
                        value={graphY}
                        // label="Y-Label"
                        onChange={handleGraphYChange}
                        style={SELECT_STYLE}
                        sx={SELECT_SX}
                    >
                        {rowNames.map((h, i) => (
                            <MenuItem key={i.toString()} value={i} color={"var(--text-color)"}>
                                {h}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControlLabel
                    control={
                        <Checkbox
                            defaultChecked
                            checked={showAllRowsGraph}
                            onChange={() =>
                                setShowAllRowsGraph(!showAllRowsGraph)
                            }
                            sx={{
                                ".MuiCheckbox-root": {
                                    fill: "white",
                                    borderColor: "white",
                                },
                                ".MuiSvgIcon-root": {
                                    fill: "var(--text-color)",
                                },
                            }}
                        />
                    }
                    label="All Rows?"
                />
            </Grid>
            
            <Grid container width="100%" height="65vh">
                <ScatterChart
                    labelX={rowNames[graphX]}
                    labelY={rowNames[graphY]}
                    // labelData= {[...rows]}
                    labelData={showAllRowsGraph ? [...rows] : [...showRows]}
                    xIndex={graphX}
                    yIndex={graphY}

                    showTableEntry={showTableEntry}
                />
            </Grid>

            <Grid container justifyContent="space-between">
                <Button
                    onClick={() => {
                        let tmp = page - 1;
                        if (tmp < 0) {
                            tmp = NUMBER_OF_PAGES - 1;
                        }
                        setPage(tmp);
                    }}
                    color="primary"
                    variant="contained"
                >
                    Previous Page {page + 1}/{NUMBER_OF_PAGES}
                </Button>

                <Button
                    onClick={() => {
                        setPage((page + 1) % NUMBER_OF_PAGES);
                    }}
                    color="primary"
                    variant="contained"
                >
                    Next Page {page + 1}/{NUMBER_OF_PAGES}
                </Button>
            </Grid>
            <TableContainer component={Paper} id={tableID}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    {drawHead()}
                    <TableBody>
                        {showRows.map((row, i) => (
                            <TableRow
                                key={`output-table-row-${row[0]}`}
                                id={`output-table-row-${row[0]}`}
                                sx={{
                                    "&:last-child td, &:last-child th": {
                                        border: 0,
                                    },
                                }}
                            >
                                {row.map((val, valIdx) => (
                                    <TableCell
                                        className="tablecelltext"
                                        align="left"
                                        key={val.toString() + valIdx.toString()}
                                    >
                                        {rowNames[valIdx]
                                            .toLowerCase()
                                            .includes("smile")
                                            ? drawTableSmiles(val)
                                            : val}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                    {drawHead()}
                </Table>
            </TableContainer>
        </div>
    );
}
