import React from 'react'
import "./imprint.css";

const Imprint = () => {
    return (
        <div style={{textAlign:"left", marginLeft: "1em"}}>
            <h3 style={{textDecorationLine:"underline", textAlign:"left"}}>Imprint</h3>
            {`
            The Fraunhofer Institute for Algorithms 
            and Scientific Computing SCAI
            
            Schloss Birlinghoven 1
            53757 Sankt Augustin
            
            
            Germany
            
            Phone +49 2241 14-4232
            Fax +49 2241 14-2460
            `.split("\n").map(s => <p className="line">{s}</p>)}

            <p><a className="line" href="https://www.scai.fraunhofer.de">www.scai.fraunhofer.de</a></p>
            
            {`
            is a constituent entity of the Fraunhofer-Gesellschaft, and as such has no separate legal status.

            Fraunhofer-Gesellschaft 
            zur Förderung der angewandten Forschung e.V.
            Hansastraße 27 c
            80686 München
            
            `.split("\n").map(s => <p className="line">{s}</p>)}
            
            <p className="line"> E-Mail: <a className="line" href="mailto:info@zv.fraunhofer.de">info(at)zv.fraunhofer.de</a></p>
            
            <p ><a className="line" href="https://www.fraunhofer.de">Internet: www.fraunhofer.de</a></p>
            

            <h4>Responsible editor:</h4>

            <p className="line">Astrid Maaß</p>
            <p><a className="line" href="mailto:astrid.maass@scai.fraunhofer.de">astrid.maass(at)scai.fraunhofer.de</a></p>
            
            {
                `
                VAT Identification Number in accordance with §27 a VAT Tax Act: DE 129515865

                Court of jurisdiction
                Amtsgericht  München (district court)
                Registered nonprofit association 
                Registration no. VR 4461  
                    
                `.split("\n").map(s => <p className="line">{s}</p>)
            }

            
            <h4>Executive Board</h4>
            {
                `
                Prof. Holger Hanselka | President
                Prof. Axel Müller-Groeling | Member of the executive board
                Elisabeth Ewen | Member of the executive board
                Dr. Sandra Krey | Member of the executive board  
                `.split("\n").map(s => <p className="line">{s}</p>)
            }
            <h4>Usage Rights</h4>
            {
                `
                © Fraunhofer-Gesellschaft e.V., München

                All rights reserved. 

                All copyright for this Web site are owned in full by the Fraunhofer-Gesellschaft.

                Permission is granted to download or print material published on this site for personal use only. Its use for any other purpose, and in particular its commercial use or distribution, are strictly forbidden in the absence of prior written approval.

                Notwithstanding this requirement, material may be downloaded or printed for use in connection with press reports on the activities of the Fraunhofer-Gesellschaft and its constituent institutes, on condition that the following terms are complied with:

                No alterations may be made to pictorial content, with the exception of framing modifications to emphasize the central motif. The source must be quoted and two free reference copies must be sent to the above-mentioned address. Such usage is free of charge.
                Disclaimer

                We cannot assume any liability for the content of external pages. Solely the operators of those linked pages are responsible for their content.

                We make every reasonable effort to ensure that the content of this Web site is kept up to date, and that it is accurate and complete. Nevertheless, the possibility of errors cannot be entirely ruled out. We do not give any warranty in respect of the timeliness, accuracy or completeness of material published on this Web site, and disclaim all liability for (material or non-material) loss or damage incurred by third parties arising from the use of content obtained from the Web site.

                Registered trademarks and proprietary names, and copyrighted text and images, are not generally indicated as such on our Web pages. But the absence of such indications in no way implies that these names, images or text belong to the public domain in the context of trademark or copyright law.
                
                `.split("\n").map(s => <p className="line">{s}</p>)
            }
            
        </div>
    )
}

export default Imprint;
