import React from "react";
import {TextField, Grid} from '@mui/material';

function NumberInputField(props) {
    return <Grid item
        justifyContent="center"
        alignItems="center" margin="1em">
    
        <h5 style={{color: "var(--text-color)", }}>{props.text}</h5>
        <TextField
            type="number"
            value={props.value}
            variant="outlined"
            style={{borderColor: "var(--text-color)", color: "var(--text-color)"}}
            inputProps={{
                maxLength: 13,
                step: props.decimals ? "0.1" : "1.0"
            }}
            onChange={(e) => props.setFunc(e.target.value.replace(",","."))}

            sx={
                {
                    ".MuiOutlinedInput-input" : {
                        color: "white",
                        borderColor: "white",
                        outline: "auto"
                    },
                }
            }
        />
        {/* < Input
            step={props.decimals ? 0.01 : 1 }
            placeholder={
                props.placeholder
            }
            color="success"
            type="number"
            onChange={
                (threshold) => props.setFunc(threshold.target.value)
            }
            value={
                props.value
            }
            style={
                {
                    backgroundColor: "rgba(255,255,255,0.4)",
                    color: "var(--text-color)"
                }
            }
        /> */}
        </Grid>;

}

export default NumberInputField;