import * as React from "react";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { StyledTab, StyledTabs, TabPanel } from "./TabComponents";
import { CircularProgress, Tooltip } from "@mui/material";

import MoleculeStructure from "./MoleculeStructure";


const DEFAULT_SMILES = [
    "C1=CN=C(C=N1)C(=O)O",
    "C1=CC=2NC3=CC(C(C=C3NC2C=C1S(=O)(=O)O)=O)=O",
    "C1=CN=CC=N1",
    "C1=CC=C2C(=C1)N=CC=N2",
    "C([C@H]([C@@H](CS1)O)O)S1",
    "C1=CC2=C(C=C1S(=O)(=O)O)C(=O)C3=C(C2=O)C=C(C=C3)S(=O)(=O)O ",
    "C1=CC=C2C(=C1)C(=O)C3=C(C2=O)C=C(C=C3)S(=O)(=O)O",
    "C1=CC=C2C(=C1)C(=O)C3=C(C2=O)C(=CC=C3)S(=O)(=O)O",
    "C1=CC2=C(C(=C1)S(=O)(=O)O)C(=O)C3=C(C2=O)C=CC=C3S(=O)(=O)O",
    "CC=O",
    "CC1=CC2=C(C=C1C)N(C3=NC(=O)NC(=O)C3=N2)C[C@@H]([C@@H]([C@@H](COP(=O)(O)O)O)O)O",
    "CC1=CC2=C(C=C1C)N(C3C(=N2)C(=O)NC(=O)N=3)C",
    "C(C(=O)CO)O",
    "CC(=O)C(=O)O",
    "C1=CC2=C(C=C1S(=O)(=O)O)C(=O)C3=C(C2=O)C=CC(=C3)S(=O)(=O)O",
    "C1=CC2=C(C(=C1)S(=O)(=O)O)C(=O)C3=C(C2=O)C(=CC=C3)S(=O)(=O)O",
    "C(C(=O)C(=O)O)C(=O)O",
    "CC1=C(C)C(=O)c2ccccc2C1=O",
    "C1=CC=C2C(=C1)N=C3C=CC=CC3=N2",
    "C(=C/C(=O)O)\C(=O)O",
    "C([C@@H]([C@@H]1C(=O)C(=O)C(=O)O1)O)O",
    "CC1=C(C(=O)C(=C(C1=O)OC)OC)C",
    "O=C1C=CC(=O)C=C1",
    "c1cc(c(cc1CCN)=O)=O",
    "O=C1C=CC=CC1=O",
    "C1=C(C=C(C(C1=O)=O)S(=O)(=O)O)S(=O)(=O)O",
]

export default function InputTabs(props) {
    const [value, setValue] = React.useState(0);

    

    const [smilesText, setSmilesText] = React.useState(
        DEFAULT_SMILES.join("\n")
    );
    const [smilesList, setSmilesList] = React.useState(
        DEFAULT_SMILES
    );
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const parseSmilesText = (text) => {
        let smiles = text.split("\n");
        if (smiles.length === 0) {
            return;
        }

        smiles = smiles.map((s) => s.replace(" ", ""));
        smiles = smiles.filter((s) => s != "" && s != undefined);
        let list = [];
        // Have a maximum of 50 SMILES 
        for (let i = 0; i < Math.min(50, smiles.length); i++) {
            list.push(smiles[i]);
        }
        smiles = list;
        console.log(smilesList);
        setSmilesText(text);
        setSmilesList(smiles);
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ bgcolor: "var(--main-bg-color)", width: "100%" }}>
                <StyledTabs
                    value={value}
                    onChange={handleChange}
                    aria-label=""
                    centered
                >
                    <StyledTab label="Enter Smiles" />
                </StyledTabs>

                <TabPanel
                    value={value}
                    index={0}
                    style={{ justifyContent: "center" }}
                >
                    <Grid container  direction="row" spacing={3}>
                        <Grid  item justifyContent="flex-start" xs={4} >
                            <TextareaAutosize
                                minRows={20}
                                maxRows={50}
                                aria-label="Smiles input field"
                                defaultValue={smilesText}
                                onChange={(e) =>
                                    parseSmilesText(e.target.value)
                                }
                                style={{ width: "100%" }}
                            />
                        </Grid>

                        <Grid container item justifyContent="center" direction="row" spacing={1} xs={8}  >
                            {smilesList.map((smi, i) => (
                                <Grid item xs={1.25}> 
                                    <MoleculeStructure
                                        id={smi}
                                        structure={smi}
                                        height={125}
                                        width={125}
                                        svgMode
                                    />
                                    </Grid>
                            ))}
                        </Grid>
                    </Grid>

                    <Button
                        variant="contained"
                        color={props.calculationDone ? "success" : "primary"}
                        style={{ width: "auto", margin : "1em" }}
                        onClick={() => props.startCalculationFunc(smilesList)}
                    >
                        Start Calculation
                    </Button>
                    {props.calculationDone ? (
                        <React.Fragment />
                    ) : (
                        <React.Fragment>
                            <br /> <CircularProgress />
                        </React.Fragment>
                    )}
                </TabPanel>
            </Box>
        </Box>
    );
}
