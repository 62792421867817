import React from "react";
import { StyledTab, StyledTabs, TabPanel } from "./TabComponents";
import DataTable from "./DataTable";
import Box from "@mui/material/Box";
import {
    Button,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";

export default function OutputTabs(props) {
    const tabsWithValues = props.tabsWithValues;

    React.useEffect(() => {
        setValue(tabsWithValues.length - 1);
    }, [tabsWithValues]);

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const onDownload = (name, headers, rows) => {
        // const encoded = btoa(JSON.stringify(jsonData));
        const csvHeader = headers.join("|") + "\n";
        const csvBody = rows.map((e) => e.join("|")).join("\n");
        const link = document.createElement("a");
        link.download =
            name
                .toLowerCase()
                .replaceAll(" ", "_")
                .replaceAll(".", "")
                .replaceAll("-", "_") + `.csv`;
        link.href = "data:text/csv;charset=utf-8," + csvHeader + csvBody;
        link.click();
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ bgcolor: "var(--main-bg-color)", width: "100%" }}>
                <StyledTabs
                    value={value}
                    onChange={handleChange}
                    aria-label=""
                    centered
                >
                    {tabsWithValues.map((tab) => (
                        <StyledTab label={tab.name} key={tab.name} />
                    ))}
                </StyledTabs>
                {tabsWithValues.map((tab, idx) => (
                    <TabPanel
                        value={value}
                        index={idx}
                        key={JSON.stringify(tab) + idx.toString()}
                    >
                        <Grid container justifyContent="flex-end" width="100%">
                            <Button
                                onClick={() =>
                                    onDownload(
                                        tab.name,
                                        tab.headers,
                                        tab.values
                                    )
                                }
                                variant="contained"
                                color="success"
                            >
                                Download
                            </Button>
                        </Grid>
                        {/* 
                        tab.header = ["Col1", "Col2"] // List of Header Names
                        tab.values = [["col1Val", "col2Val"]] // A List of Lists
                        */}
                        <DataTable
                            rowNames={tab.headers}
                            rows={tab.values}
                            preRowNames={tab.preHeader}
                            id = {idx}
                        />

                        {tab.papers !== undefined ? 
                        (
                        <div>
                            <h3>
                            References  
                            </h3>
                            {tab.papers.map(ref => <h5 key={ref}>{ref}</h5>)}
                        </div>
                        ):
                        (<div></div>)
                        }
                        
                    {/* {tab.papers.map(ref => <p>{ref}</p>)} */}

                       
                    </TabPanel>
                ))}
            </Box>
        </Box>
    );
}
